import ApiService from "./ApiService"
import appConfig from '../configs/app.config'



export async function getReports (data) {
    return ApiService.fetchData({
        url: appConfig.APIURL + 'mobreportnew',
        method: 'get',
        headers: {'Content-Type' : 'application/json'},
        data
    })
}


// Temsilci Ziyaret İstatistiklerini döndürür
export async function getTransactionsSSM (data) {
    return ApiService.fetchData({
        url: 'https://nosimia.com.tr/efes/servis',
        method: 'post',
        headers: {'Content-Type' : 'application/json'},
        data
    })
}

// KPI Skorları döndürür
export async function getKpiScoreVisits (data) {
    return ApiService.fetchData({
        url: 'https://nosimia.com.tr/efes/servis',
        method: 'post',
        headers: {'Content-Type' : 'application/json'},
        data
    })
}

// Genel Raporları Döndürür
export async function getGeneralReports (data) {
    return ApiService.fetchData({
        url: 'https://nosimia.com.tr/efes/servis',
        method: 'post',
        headers: {'Content-Type' : 'application/json'},
        data
    })
}

// Genel Servis istekleri için kullanılır
export async function getVariableService (data) {
    return ApiService.fetchData({
        url: 'https://nosimia.com.tr/efes/servis',
        method: 'post',
        headers: {'Content-Type' : 'application/json'},
        data
    })
}

// Genel Servis istekleri için kullanılır
export async function getVariableServiceMaster (data) {
    return ApiService.fetchData({
        url: 'https://nosimia.com.tr/efes/testjson',
        method: 'get',
        headers: {'Content-Type' : 'application/json'},
        data
    })
}

export async function getKPIscoreTrends (params) {
    return ApiService.fetchData({
        url: '/stats/kpi_trends',
        method: 'get',
        params
    })
}


export async function getKPIscorePer (params) {
    return ApiService.fetchData({
        url: '/stats/kpi_per',
        method: 'get',
        params
    })
}