import { createSlice } from '@reduxjs/toolkit';

export const initialData = {
    data: {},
    show:false,
    loading: false,
    columns: [],
    index: [],
    list: [],
}

const userDrawerSlice = createSlice({
    name: 'userDrawer',
    initialState: {...initialData },
        reducers: {
            updateDrawer: (state, action) => {
                state.data = { ...action.payload }
            },
            closeDrawer: (state) => {
                state.show = false
            },
            openDrawer: (state) => {
                state.show = true
            },            
            updateList: (state, action) => {
                state.list = action.payload;
            },
            updateColumns: (state, action) => {
            state.columns = action.payload;
            },
   
    },
       
    extraReducers: {
    }
});

export const { updateDrawer, openDrawer, closeDrawer, updateList, updateColumns } = userDrawerSlice.actions

export default userDrawerSlice.reducer