import BaseService from './BaseService'

const ApiService = {
    fetchData(config) {
        return BaseService(config)
          .then(response => response)
          .catch(error => Promise.reject(error));
      },
    };
    

export default ApiService