import { combineReducers } from '@reduxjs/toolkit'
import common from './commonSlice'
import dashboard from 'store/dashboard/dashboardSlide'
import teams from 'store/teams/teamsSlide'
import visit from'store/visitDrawer/visitDrawerSlice'
import price from'store/priceDrawer/priceDrawerSlice'
import poll from'store/pollDrawer/pollDrawerSlice'
import labelcolor from'store/labelColorDrawer/labelColorDrawerSlice'
import master from 'store/master/userDrawerSlice'

const reducer = combineReducers({
    common,
    dashboard,
    teams,
    visit,
    price,
    poll,
    labelcolor,
    master
})

export default reducer